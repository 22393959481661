<template>
    <div id="main">
        <div class="flex-grow-1" />

        <div id="title">
            KALKIN TOURISME - BACK-OFFICE
        </div>
        <div id="subtitle">
            Cette application web est un outil de gestion pour les produits
            Kalkin Tourisme
        </div>

        <div class="flex-grow-1" />

        <div>
            <b-button variant="kalkin-2" to="/login">
                <b-icon-door-closed /> Se connecter
            </b-button>
        </div>

        <div class="flex-grow-1" />

        <div id="footer">
            <b-link href="mailto:contact@kalkin.fr">Nous contacter</b-link>
            <b-link href="https://kalkin.fr/">www.kalkin.fr</b-link>
            <b-link to="/legalnotice">Mentions légales</b-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "LandingPage",
};
</script>

<style scoped lang="scss">
#main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    width: 100%;
    height: 100%;

    text-align: center;

    #title {
        font-size: xx-large;
        letter-spacing: 3.75px;
        color: $primary-color;
        font-weight: bold;
    }

    #subtitle {
        letter-spacing: 1.4px;
        color: #aaa;
    }

    #footer {
        display: flex;
        justify-content: space-around;
        width: 100%;

        * {
            flex: 1;
            color: $primary-color;
        }
    }
}
</style>
